@import '../../../../scss/theme-bootstrap';

.site-header-menu {
  &__container {
    // Menu Hidden.
    @include transition(transform 0.7s ease);
    @include transform(translateX(-100%));
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    color: $color-white;
    background: $color-black;
    display: flex;
    flex-direction: column;
    text-align: #{$ldirection};
    @include breakpoint($large-up) {
      @include transform(none);
      text-align: inherit;
      position: relative;
      min-height: 0;
      z-index: 1;
      padding-bottom: 0;
      width: auto;
      height: auto;
    }
    &.active {
      @include transform(translateX(0));
    }
  }
  &__wrapper {
    position: relative;
    @include breakpoint($large-up) {
      height: $nav-height;
    }
  }

  // List and link stylings
  &__list {
    margin: auto;
    padding-top: 40px;
    border-bottom: 1px solid $color-white;
    @include breakpoint($large-up) {
      padding: 0;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      top: 9px;
      border-bottom: none;
      max-width: 70%;
      height: 80px;
    }
    @include breakpoint($desktop-wide) {
      max-width: 80%;
    }
  }
  &__search {
    display: none;
    @include breakpoint($large-up) {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      #{$rdirection}: 0;
      padding-#{$rdirection}: 30px;
    }
  }
  &__search-results {
    display: none;
    @include breakpoint($large-up) {
      position: absolute;
      display: block;
      #{$ldirection}: 0;
      width: 100%;
    }
  }
  &__link {
    color: $color-white;
    display: block;
    text-align: #{$ldirection};
    text-decoration: none;
    padding: 0 22px 18px;
    &--refer-friend {
      span {
        color: $color-dark-pink;
        text-decoration: underline;
      }
    }
  }
  &__additional-links {
    padding-top: 20px;
  }
  &--sticky {
    display: none;
  }
  &__hamburger {
    display: block;
    line-height: $mobile-nav-height;
    padding-#{$ldirection}: 14px;
  }
  &__icon {
    &--hamburger {
      position: relative;
      color: transparent;
      &::before {
        content: '';
        background: url('/media/images/global/icons/hamburger_menu_white.svg') no-repeat center
          transparent;
        width: 25px;
        height: 25px;
        display: inline-block;
      }
    }
    &--close {
      #{$rdirection}: 22px;
      position: absolute;
      top: 15px;
      z-index: 1;
      color: transparent;
      &::before {
        content: '';
        background: url('/media/images/global/icons/close_white.svg') no-repeat center transparent;
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }
  }

  // Menu Expanded.
  &--opened {
    #{$ldirection}: 0;
    height: 100vh;
    overflow: hidden;
    .site-header-menu__container {
      @include transform(translateX(0));
      height: 100%;
      overflow-y: scroll;
    }
  }
}
